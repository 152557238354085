import Vue from "vue";
import VueRouter from "vue-router";
import layout from "../views/layout/layout";
Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        component: resolve => require(["../App.vue"], resolve),
        children: [
            { path: "/", redirect: "/index" },
            {
                path: "/index",
                name: "index",
                component: resolve =>
                    require(["../views/index/index.vue"], resolve),
                meta: {
                    title: "后台首页",
                },
            },
            {
                path: "/homework",
                component: layout,
                children: [
                    {
                        path: "/",
                        component: resolve =>
                            require([
                                "../views/homework/index/index.vue",
                            ], resolve),
                        meta: {
                            title: "插花作业",
                        },
                    },
                    {
                        path: "correctList",
                        component: resolve =>
                            require([
                                "../views/homework/correctList/correctList.vue",
                            ], resolve),
                        meta: {
                            title: "插花作业",
                        },
                    },
                    {
                        path: "statusList",
                        component: resolve =>
                            require([
                                "../views/homework/statusList/statusList.vue",
                            ], resolve),
                        meta: {
                            title: "插花作业",
                        },
                    },
                    {
                        path: "editHomeWork",
                        component: resolve =>
                            require([
                                "../views/homework/editHomeWork/editHomeWork.vue",
                            ], resolve),
                        meta: {
                            title: "插花作业",
                        },
                    },
                ],
            },
            {
                path: "/manage",
                component: layout,
                children: [
                    {
                        path: "/",
                        component: resolve =>
                            require([
                                "../views/manage/index.vue",
                            ], resolve),
                        meta: {
                            title: "学生管理",
                        },
                    },
                    {
                        path: "class",
                        component: resolve =>
                            require([
                                "../views/manage/class/class.vue",
                            ], resolve),
                        meta: {
                            title: "学生管理",
                        },
                    },
                    {
                        path: "classDetail",
                        component: resolve =>
                            require([
                                "../views/manage/classDetail/index.vue",
                            ], resolve),
                        meta: {
                            title: "标签详情",
                        },
                    },
                ],
            },
            {
                path: "/work",
                component: layout,
                children: [
                    {
                        path: "/",
                        component: resolve =>
                            require(["../views/work/work.vue"], resolve),
                        meta: {
                            title: "我的作品",
                        },
                    },
                ],
            },
            {
                path: "/teachingData",
                component: layout,
                children: [
                    {
                        path: "/",
                        component: resolve =>
                            require(["../views/teachingData/index.vue"], resolve),
                        meta: {
                            title: "教学数据",
                        },
                    },
                ],
            },
            {
                meta: {
                    title: "账号管理",
                },
                path: "/account",
                name: "account",
                component: resolve =>
                    require(["../views/account/account.vue"], resolve),
            },
            {
                path: "/courseware",
                component: layout,
                children: [
                    {
                        path: "/",
                        component: resolve => require(["../views/courseware/courseware.vue"], resolve),
                        meta: {
                            title: "教学课件",
                        },
                    },
                    {
                        path: "dataAnalysis",
                        component: resolve => require(["../views/courseware/dataAnalysis/index.vue"], resolve),
                        meta: {
                            title: "数据分析",
                        },
                    }
                ]
            },
            {
                path: "/race",
                component: layout,
                children: [
                    {
                        path: "/",
                        component: resolve =>
                            require(["../views/race/index/index.vue"], resolve),
                        meta: {
                            title: "插花比赛",
                        },
                    },
                    {
                        path: "data",
                        component: resolve =>
                            require(["../views/race/data/data.vue"], resolve),
                        meta: {
                            title: "插花比赛",
                        },
                    },
                    {
                        path: "score",
                        component: resolve =>
                            require(["../views/race/score/score.vue"], resolve),
                        meta: {
                            title: "插花比赛",
                        },
                    },
                    {
                        path: "editRace",
                        component: resolve =>
                            require(["../views/race/editRace/editRace.vue"], resolve),
                        meta: {
                            title: "插花比赛",
                        },
                    },
                ],
            },
            {
                path: "/future",
                component: layout,
                children: [
                    {
                        path: "/",
                        component: resolve =>
                            require([
                                "../views/future/courseware.vue",
                            ], resolve),
                        meta: {
                            title: "课件",
                        },
                    },
                ],
            },

            {
                path: "/materials",
                component: resolve => require(["../views/materials/index.vue"], resolve),
                redirect: "/materials/flower/list",
                children: [
                    {
                        path: "flower/list",
                        component: resolve => require(["../views/materials/children/flower/list.vue"], resolve),
                    },
                    {
                        path: "flower/detail",
                        component: resolve => require(["../views/materials/children/flower/detail.vue"], resolve),
                    },
                    {
                        path: "container/list",
                        component: resolve => require(["../views/materials/children/container/list.vue"], resolve),
                    },
                    {
                        path: "container/detail",
                        component: resolve => require(["../views/materials/children/container/detail.vue"], resolve),
                    }
                ]
            },
        ],
    },
    {
        path: "/login",
        component: () => import("../views/login/login.vue"),
    },
    {
        path: "/err/expire",
        component: () => import("../views/error/expire.vue"),
        meta: {
            title: "绿数花旅",
        },
    },
    {
        path: "/err/examine",
        component: () => import("../views/error/examine.vue"),
        meta: {
            title: "绿数花旅",
        },
    },
    {
        path: "/admin",
        component: () => import("../views/admin/admin.vue"),
    },
];

const router = new VueRouter({
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    next();
});

export default router;
